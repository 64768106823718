import i18n from 'i18next'
import AppConfig from '../Config/AppConfig'

// Available languages
const LanguageResources = {
  'en-GB': {
    translations: require('./languages/en_GB.json')
  }
}

// Detect browser languages
const detectBrowserLanguages = () => {
  const locales = AppConfig.project.languages
  const fallbackLocale = locales[0]

  let currentLocale = null

  if (navigator.languages) {
    // Check preffered languages

    // ...for direct match
    for (const locale of locales) {
      for (const language of navigator.languages) {
        if (locale.toLowerCase() === language.toLowerCase()) {
          currentLocale = locale
          break
        }
      }
    }

    // ...for partial match
    if (currentLocale === null) {
      for (const locale of locales) {
        for (const language of navigator.languages) {
          if (locale.toLowerCase().indexOf(language.toLowerCase()) > -1) {
            currentLocale = locale
            break
          }
        }
      }
    }
  } else {
    // Check navigator language

    const language = navigator.language || navigator.userLanguage

    // ...for direct match
    for (const locale of locales) {
      if (locale.toLowerCase() === language.toLowerCase()) {
        currentLocale = locale
        break
      }
    }

    // ...for partial match
    if (currentLocale === null) {
      for (const locale of locales) {
        if (locale.toLowerCase().indexOf(language.toLowerCase()) > -1) {
          currentLocale = locale
          break
        }
      }
    }
  }

  // Use first language as fallback
  if (currentLocale === null) {
    currentLocale = locales[0]
  }

  return { currentLocale, fallbackLocale }
}

// Initialise i18n with detected languages fitting to AppConfig
const detectedLanguages = detectBrowserLanguages()

i18n.init({
  // we init with resources
  resources: LanguageResources,
  lng: detectedLanguages.currentLocale,
  fallbackLng: detectedLanguages.fallbackLocale,
  initImmediate: false,
  debug: false,
  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: '.', // we use content as keys
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },
  react: {
    wait: true
  }
})

export default i18n
