import React, { useState } from 'react'
import { Card, Button } from 'antd'

import './index.css'
import background from '../../Images/Login/background.jpg'
import LoginForm from './LoginForm'
import I18n from '../../I18n/I18n'
import AppConfig from '../../Config/AppConfig'
import BrandStrings from '../../Themes/BrandStrings'

const Login = () => {
  const [lang, setLanguage] = useState(I18n.language)

  function changeLanguage (lang) {
    I18n.changeLanguage(lang)
    // Change state to force a ui redraw
    setLanguage(lang)
  }

  // get array of allowed ui languages (['de', 'en', …])
  const { languages } = AppConfig.project

  return (
    <div className='loginContainer'>
      <div
        className='backgroundImage'
        style={{ backgroundImage: `url(${background})` }}
      />
      <Card
        title={
          <span style={{ fontWeight: 'bold' }}>
            {I18n.t('Login.title', {
              branding: BrandStrings.general('customerName')
            })}
          </span>
        }
        extra={
          languages && languages.length > 1 ? (
            <span>
              {languages.map((language, index) => (
                <Button
                  type={
                    lang.substring(0, 2) === language.substring(0, 2)
                      ? 'primary'
                      : 'secondary'
                  }
                  key={index}
                  onClick={() => {
                    changeLanguage(language)
                  }}
                  style={{ marginLeft: 2 }}
                >
                  {language.substring(0, 2).toUpperCase()}
                </Button>
              ))}
            </span>
          ) : null
        }
        className='loginCard'
        bordered
        style={{ width: 500, margin: 'auto auto', paddingBottom: 0 }}
      >
        <LoginForm language={lang} />
      </Card>
    </div>
  )
}

export default Login
