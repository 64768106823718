import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import Log from '../Utils/Log'
const log = new Log('Redux/GUIRedux')

/* ------------- Actions and Action Creators ------------- */

/* Redux-Sauce automatically creates actions according to the following scheme:
 * loginRequest: ['username', 'token'] =>
 * (username, token) => {
 *    type: LOGIN_REQUEST
 *    username: username,
 *    token: token
 *  }
 */

const { Types, Creators } = createActions({
  login: ['username', 'token', 'team'],
  logout: [],
  legalDocumentsAccepted: []
})

export const GUIActions = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loggedIn: false,
  username: null,
  token: null,
  team: null,
  legalDocumentsToAccept: []
})

/* ------------- Reducers ------------- */

// Login
export const login = (
  state,
  { username, token, team, legalDocumentsToAccept }
) => {
  log.debug('login')
  return {
    ...state,
    loggedIn: true,
    username,
    token,
    team,
    legalDocumentsToAccept
  }
}

// Logout
export const logout = (state) => {
  log.debug('logout')
  return {
    ...state,
    loggedIn: false,
    username: null,
    token: null,
    team: null,
    legalDocumentsToAccept: []
  }
}

// Legal documents accepted
export const legalDocumentsAccepted = (state) => {
  log.debug('legalDocumentsAccepted')
  return {
    ...state,
    legalDocumentsToAccept: []
  }
}
/* ------------- Hookup Reducers To Actions ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN]: login,
  [Types.LOGOUT]: logout,
  [Types.LEGAL_DOCUMENTS_ACCEPTED]: legalDocumentsAccepted
})
