import React from 'react'
import { Card } from 'antd'

import './index.css'
import background from '../../Images/Login/background.jpg'
import LegalDocumentsForm from './LegalDocumentsForm'
import I18n from '../../I18n/I18n'

const LegalDocuments = () => {
  return (
    <div className='legalDocumentsContainer'>
      <div
        className='backgroundImage'
        style={{ backgroundImage: `url(${background})` }}
      />
      <Card
        title={
          <span style={{ fontWeight: 'bold' }}>
            {I18n.t('LegalDocuments.title')}
          </span>
        }
        className='legalDocumentsCard'
        bordered
        style={{ width: 500, margin: 'auto auto', paddingBottom: 0 }}
      >
        <LegalDocumentsForm />
      </Card>
    </div>
  )
}

export default LegalDocuments
