import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom'
import { connect } from 'react-redux'

import AppConfig from '../Config/AppConfig'
import Dashboard from '../Components/Dashboard'
import Login from '../Components/Login'
import LegalDocuments from '../Components/LegalDocuments'

class AppNavigation extends Component {
  render () {
    const { loggedIn, legalDocumentsToAccept } = this.props

    const basePath = AppConfig.project.path
    const loginPath = basePath + 'login'
    const legalDocumentsPath = basePath + 'legal-documents'
    const dashboardPath = basePath + 'dashboard'

    // Route params:
    // exact -> no "subroutes" (e.g. user/details)
    // strict -> no trailing slashes
    return (
      <Router>
        <Switch>
          <Route
            exact
            path={basePath}
            render={(props) =>
              loggedIn ? (
                legalDocumentsToAccept.length === 0 ? (
                  <Redirect to={dashboardPath} />
                ) : (
                  <Redirect to={legalDocumentsPath} />
                )
              ) : (
                <Redirect to={loginPath} />
              )
            }
          />
          <Route
            exact
            path={loginPath}
            render={(props) => {
              return <Login {...props} />
            }}
          />
          <Route
            exact
            path={legalDocumentsPath}
            render={(props) => {
              return <LegalDocuments {...props} />
            }}
          />
          <Route
            path={dashboardPath}
            render={(props) =>
              loggedIn ? (
                legalDocumentsToAccept.length === 0 ? (
                  <Dashboard {...props} />
                ) : (
                  <LegalDocuments {...props} />
                )
              ) : (
                <Redirect to={loginPath} />
              )
            }
          />
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.guiState.loggedIn,
  legalDocumentsToAccept: state.guiState.legalDocumentsToAccept
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AppNavigation)
