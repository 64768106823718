import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Image,
  Button,
  Popconfirm,
  Col,
  Row,
  Space,
  Divider,
  Input
} from 'antd'
import { App } from 'antd'

import I18n from '../../../I18n/I18n'
import {
  getTeamSecrets,
  getSecretQRCode,
  updateSecret
} from '../../../Services/ServerAccessService'

import Log from '../../../Utils/Log'
const log = new Log('Components/Dashboard/Content/App')

const { Content } = Layout
const { Title } = Typography
const { TextArea } = Input

// Styles
const invisibleSpace = {
  padding: 15
}

const urlTextAreaStyle = {
  width: 300,
  marginTop: 20
}

const renewButtonStyle = { marginTop: 30 }

function withHooks (WrappedComponent) {
  return function (props) {
    const app = App.useApp()

    return <WrappedComponent app={app} {...props} />
  }
}

class AppCodes extends Component {
  constructor (props) {
    super(props)

    // Only default values must be defined (with null)
    this.state = {
      developmentQRCode: null,
      productionQRCode: null,
      developmentSecretURL: '',
      productionSecretURL: ''
    }
  }

  async componentDidMount () {
    await this.getSecretQRCode(false)
    await this.getSecretQRCode(true)
    await this.getSecrets()
  }

  // Get data from server
  async getSecretQRCode (production = true) {
    const result = await getSecretQRCode(production)

    this.setState(
      production ? { productionQRCode: result } : { developmentQRCode: result }
    )
  }

  // Get data from server
  async getSecrets () {
    const result = await getTeamSecrets()

    log.debug('Get result:', result)

    this.setState({
      ...result
    })
  }

  // Update data on server
  async updateSecret (production = true) {
    log.debug('Update secret:', production)

    const result = await updateSecret(production)

    log.debug('Update result:', result)

    if (result === null) {
      // Unknown error
      this.props.app.message.error(I18n.t('Common.remoteSyncError'))
    } else if (result === undefined) {
      // Inconsistent
      this.props.app.message.warning(I18n.t('Common.remoteSyncInconsistent'))

      await this.getSecretQRCode(production)
      await this.getSecrets()
    } else {
      // Success
      this.props.app.message.success(I18n.t('Common.remoteSyncSuccess'))

      await this.getSecretQRCode(production)
      await this.getSecrets()
    }
  }

  render () {
    const {
      developmentQRCode,
      productionQRCode,
      developmentSecretURL,
      productionSecretURL
    } = this.state

    return (
      <Content>
        <Title level={2}>{I18n.t('App.title')}</Title>
        <Divider />
        {I18n.t('App.infoText')}
        <Divider />
        <div style={invisibleSpace} />
        <Row justify='center' align='middle'>
          <Space size={25} style={{ textAlign: 'center' }}>
            <Col>
              <Title level={3}>{I18n.t('App.qrCodeDev')}</Title>
              {developmentQRCode ? (
                <Image
                  width={200}
                  height={200}
                  src={developmentQRCode}
                  fallback='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAAAAACIM/FCAAABt0lEQVR4Ae3Ws56YTRzH0XkZ22lj26ijLrbTxGxzG+nSxqhi27ZtW8vZWV3Bs/s5v+77r+Z0E3IryUBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEAqDQQEBAQEBAQE5NGPsv3hVdnOefSrAkC+r2wWaoy5V3I4Puif0G51TnF/WdEo1Jr8JOuQz51CwapdTodVfxceZqd+27Kwa9/NOGRBiBuUE/tO9dh/HciNmxrihmYb8uXfULQz8bA09ajYL0ParUxD9oe0DfHQL3WP2FtD2s5MQ/aEtLXx0Ct119ibQtr2TEOehbSL8TAt9fjY10Pag0xDckeEuMFFffCvEHcods7goh6em23I0yahYA0upsOKULh5qe/UK+ymtzIOyX01tW5oMuVlyWFt+7/+77apFHVcrdB8ztsK8EXJKf/Ijz/L9p93Po0gGRsICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgISB4YR8X98hmMwgAAAABJRU5ErkJggg=='
                />
              ) : (
                <Image
                  width={200}
                  height={200}
                  src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAAAAACIM/FCAAABt0lEQVR4Ae3Ws56YTRzH0XkZ22lj26ijLrbTxGxzG+nSxqhi27ZtW8vZWV3Bs/s5v+77r+Z0E3IryUBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEAqDQQEBAQEBAQE5NGPsv3hVdnOefSrAkC+r2wWaoy5V3I4Puif0G51TnF/WdEo1Jr8JOuQz51CwapdTodVfxceZqd+27Kwa9/NOGRBiBuUE/tO9dh/HciNmxrihmYb8uXfULQz8bA09ajYL0ParUxD9oe0DfHQL3WP2FtD2s5MQ/aEtLXx0Ct119ibQtr2TEOehbSL8TAt9fjY10Pag0xDckeEuMFFffCvEHcods7goh6em23I0yahYA0upsOKULh5qe/UK+ymtzIOyX01tW5oMuVlyWFt+7/+77apFHVcrdB8ztsK8EXJKf/Ijz/L9p93Po0gGRsICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgISB4YR8X98hmMwgAAAABJRU5ErkJggg=='
                />
              )}
              <br />
              <TextArea
                placeholder=''
                disabled
                style={urlTextAreaStyle}
                value={developmentSecretURL}
                autoSize={{
                  minRows: 2
                }}
              />
              <br />
              <Popconfirm
                title={I18n.t('App.popupTitle')}
                description={I18n.t('App.popupContent')}
                onConfirm={() => {
                  this.updateSecret(false)
                }}
                onCancel={null}
                okText={I18n.t('Common.ok')}
                cancelText={I18n.t('Common.cancel')}
              >
                <Button danger style={renewButtonStyle}>
                  {I18n.t('Common.renew')}
                </Button>
              </Popconfirm>
            </Col>
            <Divider type='vertical' />
            <Col>
              <Title level={3}>{I18n.t('App.qrCodeProd')}</Title>
              {productionQRCode ? (
                <Image
                  width={200}
                  height={200}
                  src={productionQRCode}
                  fallback='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAAAAACIM/FCAAABt0lEQVR4Ae3Ws56YTRzH0XkZ22lj26ijLrbTxGxzG+nSxqhi27ZtW8vZWV3Bs/s5v+77r+Z0E3IryUBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEAqDQQEBAQEBAQE5NGPsv3hVdnOefSrAkC+r2wWaoy5V3I4Puif0G51TnF/WdEo1Jr8JOuQz51CwapdTodVfxceZqd+27Kwa9/NOGRBiBuUE/tO9dh/HciNmxrihmYb8uXfULQz8bA09ajYL0ParUxD9oe0DfHQL3WP2FtD2s5MQ/aEtLXx0Ct119ibQtr2TEOehbSL8TAt9fjY10Pag0xDckeEuMFFffCvEHcods7goh6em23I0yahYA0upsOKULh5qe/UK+ymtzIOyX01tW5oMuVlyWFt+7/+77apFHVcrdB8ztsK8EXJKf/Ijz/L9p93Po0gGRsICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgISB4YR8X98hmMwgAAAABJRU5ErkJggg=='
                />
              ) : (
                <Image
                  width={200}
                  height={200}
                  src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAAAAACIM/FCAAABt0lEQVR4Ae3Ws56YTRzH0XkZ22lj26ijLrbTxGxzG+nSxqhi27ZtW8vZWV3Bs/s5v+77r+Z0E3IryUBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEAqDQQEBAQEBAQE5NGPsv3hVdnOefSrAkC+r2wWaoy5V3I4Puif0G51TnF/WdEo1Jr8JOuQz51CwapdTodVfxceZqd+27Kwa9/NOGRBiBuUE/tO9dh/HciNmxrihmYb8uXfULQz8bA09ajYL0ParUxD9oe0DfHQL3WP2FtD2s5MQ/aEtLXx0Ct119ibQtr2TEOehbSL8TAt9fjY10Pag0xDckeEuMFFffCvEHcods7goh6em23I0yahYA0upsOKULh5qe/UK+ymtzIOyX01tW5oMuVlyWFt+7/+77apFHVcrdB8ztsK8EXJKf/Ijz/L9p93Po0gGRsICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgISB4YR8X98hmMwgAAAABJRU5ErkJggg=='
                />
              )}
              <br />
              <TextArea
                placeholder=''
                disabled
                style={urlTextAreaStyle}
                value={productionSecretURL}
                autoSize={{
                  minRows: 2
                }}
              />
              <br />
              <Popconfirm
                title={I18n.t('App.popupTitle')}
                description={I18n.t('App.popupContent')}
                onConfirm={() => {
                  this.updateSecret(true)
                }}
                onCancel={null}
                okText={I18n.t('Common.ok')}
                cancelText={I18n.t('Common.cancel')}
              >
                <Button danger style={renewButtonStyle}>
                  {I18n.t('Common.renew')}
                </Button>
              </Popconfirm>
            </Col>
          </Space>
        </Row>
      </Content>
    )
  }
}

export default withHooks(AppCodes)
