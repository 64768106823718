import { createBrowserHistory } from 'history'
import { combineReducers, applyMiddleware, createStore } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'

const history = createBrowserHistory()

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    guiState: require('./GUIRedux').reducer
  })

// creates the store
const configureStore = () => {
  const store = createStore(
    createRootReducer(history),
    composeWithDevTools(applyMiddleware(routerMiddleware(history)))
  )
  return store
}

const createReduxStore = () => {
  const store = configureStore()
  return { store }
}

export default createReduxStore
