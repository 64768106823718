import axios from 'axios'
import { jwtDecode } from 'jwt-decode'

import AppConfig from '../Config/AppConfig'
import I18n from '../I18n/I18n'
import { GUIActions } from '../Redux/GUIRedux'

import Log from '../Utils/Log'
const log = new Log('Services/ServerAccessService')

let _initialized = false

// Redux store
let _store = null

// Current username and token
let _username = null
let _token = null

// Axios configuration
const _basicAxiosConfiguration = {
  baseURL: AppConfig.serverAccess.restURL
}

const _basicPMCPApiAxiosConfiguration = {
  baseURL: AppConfig.serverAccess.pmcpRestURL
}

// Ping variables
let _ping = false
let _pingTimeout = false
const _pingPeriod = 1000 * 60 * 4.5 // 4.5 min

/**
 * Initialize server access service
 */
export function initialize (store) {
  if (!_initialized) {
    log.debug('Initializing server access service...')

    // called in App
    _store = store

    _initialized = true

    log.debug('Done.')
  }
}

/**
 * Perform login
 *
 * @returns response code as String
 */
export async function login (username, password, authCode) {
  log.debug(`Login Attempt with ${username} and password`)

  try {
    const response = await axios.post(
      'v00/authentication/login',
      {
        username,
        password,
        twoFactorKey: authCode,
        locale: I18n.language
      },
      { ..._basicAxiosConfiguration, validateStatus: () => true }
    )

    if (response?.status === 200 && response?.data?.token) {
      log.debug('Login successful... checking token...')

      const tokenData = jwtDecode(response.data.token)

      // Checks if all relevant fields are available in token
      if (
        !tokenData.username ||
        !tokenData.team ||
        !tokenData.role ||
        !tokenData.instanceId
      ) {
        throw new Error('Token does not contain all relevant fields')
      }

      // Checks if iss and sub is equal to AppConfig in token data
      if (
        tokenData.iss !== AppConfig.serverAccess.token.iss ||
        tokenData.sub !== AppConfig.serverAccess.token.sub
      ) {
        throw new Error('Token does not contain valic iss and sub')
      }

      log.debug('Token valid.')

      // Login successful
      _username = tokenData.username
      _token = response.data.token

      // Convert legalDocumentsToAccept
      const legalDocumentsToAccept = JSON.parse(
        response.data.legalDocumentsToAccept
      )

      // Save user and token to GUIRedux
      _store.dispatch({
        type: GUIActions.LOGIN,
        username: _username,
        token: _token,
        team: tokenData.team,
        legalDocumentsToAccept
      })

      // Activate periodical ping to refresh token
      enablePing()

      if (legalDocumentsToAccept.length === 0) {
        return 'ok'
      } else {
        return 'legal-documents'
      }
    } else if (response && response.status === 403) {
      // blacklisted
      return 'blocked'
    } else {
      // login error
      return 'error'
    }
  } catch (error) {
    console.warn('Error during login:', error)
    return 'error'
  }
}

/**
 * Perform logout
 */
export function logout () {
  disablePing()

  // Logout in GUIRedux
  _store.dispatch({
    type: GUIActions.LOGOUT
  })

  _username = null
  _token = null
}

/**
 * Send ping
 */
async function sendPing () {
  log.debug('Sending ping...')

  try {
    const response = await axios.get('v00/authentication/ping', {
      ..._basicAxiosConfiguration,
      headers: {
        Authorization: _token
      }
    })
    if (response.status === 200 && response.data?.token) {
      const tokenData = jwtDecode(response.data.token)

      // Checks if iss and sub is equal to AppConfig in token data
      if (
        tokenData.iss !== AppConfig.serverAccess.token.iss ||
        tokenData.sub !== AppConfig.serverAccess.token.sub
      ) {
        throw new Error('Invalid iss or sub in authentication token')
      }

      // Remember new token
      _token = response.data.token
      log.debug('Ping successful.')
    } else {
      // Logout on token response error
      logout()
    }
  } catch (e) {
    // Lougout on token retrieval error
    log.warn('Error during ping:', e)
    logout()
  }
}

/**
 * Enable ping
 */
function enablePing () {
  log.debug('Enable ping')

  if (_ping === false) {
    _ping = true
  } else {
    sendPing()
  }

  // Ensure that this funtion will be automatically called again after pingPeriod
  _pingTimeout = window.setTimeout(() => {
    enablePing()
  }, _pingPeriod)
}

/**
 * Disable ping
 */
function disablePing () {
  log.debug('Disable ping')

  _ping = false
  clearTimeout(_pingTimeout)
}

/**
 * Enable to get token
 *
 * @returns current token
 */
export function getToken () {
  return _token
}

/**
 * Accept legal documents
 */
export async function acceptLegalDocuments (legalDocumentsToAccept) {
  log.debug('Accept legal documents')

  try {
    await axios.post('v04/pcms/acceptLegalDocuments', legalDocumentsToAccept, {
      ..._basicPMCPApiAxiosConfiguration,
      headers: {
        Authorization: _token
      }
    })

    // Remember legal document accepted in GUIRedux
    _store.dispatch({
      type: GUIActions.LEGAL_DOCUMENTS_ACCEPTED
    })

    log.debug('Legal documents accepted.')

    return 'ok'
  } catch (e) {
    console.warn('Error during REST call:', e)
    logout()
  }
}

/*
 *TEAM MEDIA
 */

/**
 * Get media list
 *
 * @returns media list
 */
export async function getMediaList (purpose = null) {
  log.debug('Get team media')

  try {
    const response = await axios.get(
      purpose === null ? 'v03/media/list' : `v03/media/list/${purpose}`,
      {
        ..._basicAxiosConfiguration,
        headers: {
          Authorization: _token
        }
      }
    )

    return response.data
  } catch (e) {
    console.warn('Error during REST call:', e)
    if (e.response?.status === 401) {
      logout()
    }
    return null
  }
}

/**
 * Delete medium
 */
export async function deleteMedium (mediaId) {
  log.debug('Delete medium')

  try {
    const response = await axios.delete(`v03/media/${mediaId}`, {
      ..._basicAxiosConfiguration,
      headers: {
        Authorization: _token
      }
    })

    return response.data
  } catch (e) {
    console.warn('Error during REST call:', e)
    if (e.response?.status === 401) {
      logout()
    }
    if (e.response?.status === 403 || e.response?.status === 409) {
      return undefined
    }
    return null
  }
}

/*
 * TEAM AVATARS
 */

/**
 * Get team avatars
 *
 * @returns team avatars
 */
export async function getTeamAvatars () {
  log.debug('Get team avatars')

  try {
    const response = await axios.get('v03/team/avatars', {
      ..._basicAxiosConfiguration,
      headers: {
        Authorization: _token
      }
    })

    return response.data
  } catch (e) {
    console.warn('Error during REST call:', e)
    if (e.response?.status === 401) {
      logout()
    }
    return null
  }
}

/**
 * Update team avatars
 *
 * @returns updated team avatars
 */
export async function updateTeamAvatars (update) {
  log.debug('Update team avatars')

  try {
    const response = await axios.put('v03/team/avatars', update, {
      ..._basicAxiosConfiguration,
      headers: {
        Authorization: _token
      }
    })

    return response.data
  } catch (e) {
    console.warn('Error during REST call:', e)
    if (e.response?.status === 401) {
      logout()
    }
    if (e.response?.status === 409) {
      return undefined
    }
    return null
  }
}

/*
 * TEAM SECRETS
 */

/**
 * Get team secrets
 *
 * @returns team secrets
 */
export async function getTeamSecrets () {
  log.debug('Get team secrets')

  try {
    const response = await axios.get('v03/team/secrets', {
      ..._basicAxiosConfiguration,
      headers: {
        Authorization: _token
      }
    })

    return response.data
  } catch (e) {
    console.warn('Error during REST call:', e)
    if (e.response?.status === 401) {
      logout()
    }
    return null
  }
}

/*
 * TEAM BASICS
 */

/**
 * Get team basics
 *
 * @returns team basics
 */
export async function getTeamBasics () {
  log.debug('Get team basics')

  try {
    const response = await axios.get('v03/team/basics', {
      ..._basicAxiosConfiguration,
      headers: {
        Authorization: _token
      }
    })

    return response.data
  } catch (e) {
    console.warn('Error during REST call:', e)
    if (e.response?.status === 401) {
      logout()
    }
    return null
  }
}

/**
 * Update team basics
 *
 * @returns updated team basics
 */
export async function updateTeamBasics (update) {
  log.debug('Update team basics')

  try {
    const response = await axios.put('v03/team/basics', update, {
      ..._basicAxiosConfiguration,
      headers: {
        Authorization: _token
      }
    })

    return response.data
  } catch (e) {
    console.warn('Error during REST call:', e)
    if (e.response?.status === 401) {
      logout()
    }
    if (e.response?.status === 409) {
      return undefined
    }
    return null
  }
}

/**
 * Provide secret QR code image as Base64 data
 *
 * @returns secret QR code as base64 PNG image
 */
export async function getSecretQRCode (production = true) {
  log.debug(
    'Request secret QR code for ' + (production ? 'production' : 'development')
  )

  try {
    const response = await axios.get(`v03/team/qr-code/${production}`, {
      ..._basicAxiosConfiguration,
      responseType: 'arraybuffer',
      headers: {
        Authorization: _token
      }
    })

    const base64 = btoa(
      new Uint8Array(response.data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ''
      )
    )

    return 'data:image/png;base64,' + base64
  } catch (e) {
    console.warn('Error during REST call:', e)
    if (e.response?.status === 401) {
      logout()
    }
    return null
  }
}

/**
 * Update secret
 *
 * @returns updated secret
 */
export async function updateSecret (production = true) {
  log.debug(
    'Update code secret for ' + (production ? 'production' : 'development')
  )

  try {
    const response = await axios.put(`v03/team/qr-code/${production}`, null, {
      ..._basicAxiosConfiguration,
      headers: {
        Authorization: _token
      }
    })

    return response.data
  } catch (e) {
    console.warn('Error during REST call:', e)
    if (e.response?.status === 401) {
      logout()
    }
    return null
  }
}

/*
 * TEAM COLORS
 */

/**
 * Get team colors
 *
 * @returns team colors
 */
export async function getTeamColors () {
  log.debug('Get team colors')

  try {
    const response = await axios.get('v03/team/colors', {
      ..._basicAxiosConfiguration,
      headers: {
        Authorization: _token
      }
    })

    return response.data
  } catch (e) {
    console.warn('Error during REST call:', e)
    if (e.response?.status === 401) {
      logout()
    }
    return null
  }
}

/**
 * Update team colors
 *
 * @returns updated team colors
 */
export async function updateTeamColors (update) {
  log.debug('Update team colors')

  try {
    const response = await axios.put('v03/team/colors', update, {
      ..._basicAxiosConfiguration,
      headers: {
        Authorization: _token
      }
    })

    return response.data
  } catch (e) {
    console.warn('Error during REST call:', e)
    if (e.response?.status === 401) {
      logout()
    }
    if (e.response?.status === 409) {
      return undefined
    }
    return null
  }
}
