import React, { Component } from 'react'
import { Layout, Divider, Typography, Space } from 'antd'

import I18n from '../../../I18n/I18n'
import GlobalMediaLibrary from '../Generic/MediaLibrary'

import Log from '../../../Utils/Log'
const log = new Log('Home')

const { Content } = Layout
const { Title } = Typography

// Styles
const invisibleSpace = {
  padding: 20
}

class MediaLibrary extends Component {
  render () {
    return (
      <Content>
        <Title level={2}>{I18n.t('MediaLibrary.title')}</Title>
        <Divider />
        {I18n.t('MediaLibrary.infoText')}
        <div style={invisibleSpace} />
        <GlobalMediaLibrary />
      </Content>
    )
  }
}

export default MediaLibrary
