import AppConfig from '../Config/AppConfig'

export const LEVEL_TEXTS = {
  DEBUG: 'DEBUG',
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR',
  OFF: 'OFF'
}
export const LEVEL_VALUES = {
  DEBUG: 0,
  INFO: 1,
  WARN: 2,
  ERROR: 3,
  OFF: 4
}

const loggerLength = 20

let defaultLevel = LEVEL_VALUES['DEBUG']
let loggerLevels = {}
let initialized = false

export default class Log {
  static initialize () {
    if (!initialized) {
      if (LEVEL_VALUES[AppConfig.logger.defaultLevel]) {
        defaultLevel = LEVEL_VALUES[AppConfig.logger.defaultLevel]
      }
      if (AppConfig.logger.loggerLevels) {
        loggerLevels = AppConfig.logger.loggerLevels
      }
      initialized = true
    }
  }

  constructor (name) {
    if (!initialized) {
      Log.initialize()
    }

    if (name) {
      this.loggerName = name
    } else {
      this.loggerName = 'GLOBAL'
    }
  }

  debug (message) {
    Log.writeLog(this.loggerName, LEVEL_VALUES.DEBUG, arguments)
  }
  info (message) {
    Log.writeLog(this.loggerName, LEVEL_VALUES.INFO, arguments)
  }
  warn (message) {
    Log.writeLog(this.loggerName, LEVEL_VALUES.WARN, arguments)
  }
  error (message) {
    Log.writeLog(this.loggerName, LEVEL_VALUES.ERROR, arguments)
  }

  static writeLog (logger, level, messageArguments) {
    if (messageArguments === undefined || messageArguments === null) {
      messageArguments = []
    }

    // Logger is switched off
    if (defaultLevel === LEVEL_VALUES.OFF) {
      return
    }

    // Set compare level to default level or to level specifically defined for the appropriate logger
    let compareLevel = defaultLevel
    if (loggerLevels[logger] !== undefined) {
      compareLevel = LEVEL_VALUES[loggerLevels[logger]]
    }

    let method = null
    try {
      const stackTrace = Log.getStackTrace()
      method = stackTrace.split('\n')[4].split(' ')[5]
    } catch (error) {
      // do nothing
    }

    // Care for regular logging levels
    if (level >= compareLevel) {
      let messages = null
      switch (level) {
        case LEVEL_VALUES.DEBUG:
          messages = Array.prototype.slice.call(messageArguments)
          console.log(
            Log.formatMessage(logger, LEVEL_TEXTS.DEBUG, method, messages)
          )
          break
        case LEVEL_VALUES.INFO:
          messages = Array.prototype.slice.call(messageArguments)
          console.log(
            Log.formatMessage(logger, LEVEL_TEXTS.INFO, method, messages)
          )
          break
        case LEVEL_VALUES.WARN:
          messages = Array.prototype.slice.call(messageArguments)
          console.warn(
            Log.formatMessage(logger, LEVEL_TEXTS.WARN, method, messages)
          )
          break
        case LEVEL_VALUES.ERROR:
          messages = Array.prototype.slice.call(messageArguments)
          console.error(
            Log.formatMessage(logger, LEVEL_TEXTS.ERROR, method, messages)
          )
          break
        case LEVEL_VALUES.OFF:
        default:
          break
      }
    }
  }

  static formatMessage (logger, level, method, messages) {
    let concatMessage = ''

    if (messages !== undefined && messages !== null) {
      for (let i = 0; i < messages.length; i++) {
        const message = messages[i]
        const type = Log.getType(message)
        let messagePart = ''

        switch (type) {
          case 'array':
            messagePart = '[Array] ' + message.toString()
            break
          case 'object':
            messagePart = '[JSON] ' + JSON.stringify(message)
            break
          case 'other':
            try {
              const messageToDisplay = JSON.stringify(message)
              if (messageToDisplay === undefined) {
                messagePart = '[Other] ' + message.toString()
              } else {
                messagePart = '[Other] ' + messageToDisplay
              }
            } catch (error) {
              if (
                (message && message.toString !== undefined) ||
                (message && message.toString !== null)
              ) {
                messagePart = '[Other] ' + message.toString()
              } else {
                messagePart = '[Other] <non stringifyable object>'
              }
            }

            break
          default:
            if (message === undefined) {
              messagePart = 'undefined'
            } else if (message === null) {
              messagePart = 'null'
            } else {
              messagePart = message
            }
            break
        }

        if (concatMessage.length === 0) {
          concatMessage += messagePart
        } else {
          concatMessage += ', ' + messagePart
        }
      }
    }

    let loggerString = ''
    if (defaultLevel < 4) {
      if (logger.length > loggerLength) {
        loggerString = logger.substr(logger.length - loggerLength)
      } else {
        loggerString = logger.padStart(loggerLength, ' ')
      }
    } else {
      loggerString = logger
    }

    const levelString = level.padEnd(5, ' ')

    let methodString = ''
    if (method !== null) {
      methodString = ' (@' + method + ')'
    }

    return (
      '[PM] [' +
      levelString +
      '] ' +
      loggerString +
      ': ' +
      concatMessage +
      methodString
    )
  }

  static getType (element) {
    if (Array.isArray(element)) return 'array'
    else if (typeof element === 'string') return 'string'
    else if (element !== null && typeof p === 'object') return 'object'
    else return 'other'
  }

  static getStackTrace () {
    let error = new Error()
    return error.stack
  }
}
