import React, { Component } from 'react'
import { Layout, Menu, Row } from 'antd'
import {
  LogoutOutlined,
  UserOutlined,
  FormatPainterOutlined,
  MessageOutlined,
  SettingOutlined,
  HomeOutlined,
  ToolOutlined,
  PlayCircleOutlined,
  BookOutlined
} from '@ant-design/icons'
import { Link, Route, Redirect, Switch, matchPath } from 'react-router-dom'

import logo from '../../Images/Logo/logo_light.png'
import logoIcon from '../../Images/Logo/logo_light_icon.png'
import {
  Account,
  Coach,
  Colors,
  Coaching,
  Basics,
  Home,
  App,
  MediaLibrary
} from './Content'
import I18n from '../../I18n/I18n'
import AppConfig from '../../Config/AppConfig'
import { logout } from '../../Services/ServerAccessService'
import BrandStrings from '../../Themes/BrandStrings'

const { Header, Sider, Content } = Layout
const { modules } = AppConfig

const modulesWithIcons = {
  home: <HomeOutlined />,
  basics: <ToolOutlined />,
  app: <PlayCircleOutlined />,
  coach: <UserOutlined />,
  colors: <FormatPainterOutlined />,
  mediaLibrary: <BookOutlined />,
  coaching: <MessageOutlined />,
  account: <SettingOutlined />
}

// Styles
const globalLayoutStyle = {
  width: '100%',
  height: 'calc(100vh)'
}

const headerStyle = {
  color: '#2F2F2F',
  backgroundColor: '#f5f5f5',
  padding: 0
}

const headerText = {
  paddingLeft: 25,
  fontSize: 25
}

const logoutLogoStyle = { paddingRight: 5 }

const logoutTextStyle = {
  paddingRight: 25
}

const rightLayoutStyle = {
  backgroundColor: '#fff'
}

const contentWrapperStyle = { overflow: 'auto', height: '100vh' }

const contentStyle = {
  paddingLeft: 25,
  paddingRight: 25,
  paddingBottom: 25
}

class Dashboard extends Component {
  state = {
    collapsed: false
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  getContentParam = () => {
    const { match } = this.props

    // remove trailing slash if there is one..
    switch (this.props.location.pathname.replace(/\/$/, '')) {
      case `${match.path}/account`:
        return 'account'
      case `${match.path}/app`:
        return 'app'
      case `${match.path}/home`:
        return 'home'
      case `${match.path}/basics`:
        return 'basics'
      case `${match.path}/coach`:
        return 'coach'
      case `${match.path}/colors`:
        return 'colors'
      case `${match.path}/mediaLibrary`:
        return 'mediaLibrary'
      case `${match.path}/coaching`:
        return 'coaching'
      default:
        return 'home'
    }
  }

  renderHeader = () => {
    const content = this.getContentParam()

    return (
      <Header style={headerStyle}>
        <Row justify={'space-between'}>
          <span style={headerText}>
            {I18n.t('Dashboard.header', {
              branding: BrandStrings.general('customerName')
            })}
            &nbsp;&nbsp;–&nbsp;&nbsp;
            <strong>
              {modulesWithIcons[content]}
              &nbsp;&nbsp;
              {I18n.t('Navigation.' + content)}
            </strong>
          </span>
          <div style={logoutTextStyle}>
            <Link
              onClick={() => logout()}
              className='logoutButton'
              to={AppConfig.project.path + 'dashboard/login'}
            >
              <LogoutOutlined style={logoutLogoStyle} />
              <span>{I18n.t('Dashboard.logout')}</span>
            </Link>
          </div>
        </Row>
      </Header>
    )
  }

  render () {
    const { match, location } = this.props
    const { collapsed } = this.state

    // Use matchPath to check currently active route
    const testPath = matchPath(location.pathname, {
      path: `${match.path}/:activeRoute`,
      exact: false,
      strict: false
    })
    // if no subroute was given in path (e.g. just '.../dashboard'), use coach as default route (see routing setup)
    const activeRoute =
      testPath && testPath.params ? testPath.params.activeRoute : 'home'

    const logoStyle = {
      width: collapsed ? '50%' : '80%',
      height: 30,
      margin: 20,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundImage: `url(${collapsed ? logoIcon : logo})`
    }

    return (
      <Layout style={globalLayoutStyle}>
        <Sider
          collapsible
          collapsed={this.state.collapsed}
          className='side-menu'
          onCollapse={this.toggle}
        >
          <div style={logoStyle} />
          <Menu
            theme='dark'
            selectedKeys={[activeRoute]}
            items={Object.entries(modules).map(([key, value]) => {
              if (value) {
                return {
                  key,
                  label: (
                    <Link to={`${match.url}/${key}`}>
                      {modulesWithIcons[key]}
                      <span>{I18n.t(`Navigation.${key}`)}</span>
                    </Link>
                  )
                }
              }
              return null
            })}
          />
        </Sider>
        <Layout style={rightLayoutStyle}>
          {this.renderHeader()}
          <div style={contentWrapperStyle}>
            <Content style={contentStyle}>
              <Switch>
                <Route
                  path={`${match.path}/basics`}
                  render={(props) => {
                    return <Basics />
                  }}
                />
                <Route
                  path={`${match.path}/app`}
                  render={(props) => {
                    return <App />
                  }}
                />
                <Route
                  path={`${match.path}/coach`}
                  render={(props) => {
                    return <Coach />
                  }}
                />
                <Route
                  path={`${match.path}/colors`}
                  render={(props) => {
                    return <Colors />
                  }}
                />
                <Route
                  path={`${match.path}/mediaLibrary`}
                  render={(props) => {
                    return <MediaLibrary />
                  }}
                />
                <Route
                  path={`${match.path}/coaching`}
                  render={(props) => {
                    return <Coaching />
                  }}
                />
                <Route
                  path={`${match.path}/account`}
                  render={(props) => {
                    return <Account />
                  }}
                />
                <Route
                  path={`${match.path}/home`}
                  render={(props) => {
                    return <Home />
                  }}
                />
                {/* Default Path -> coach */}
                <Route
                  path={match.path}
                  render={(props) => {
                    return <Redirect to={`${match.path}/home`} />
                  }}
                />
              </Switch>
            </Content>
          </div>
        </Layout>
      </Layout>
    )
  }
}
export default Dashboard
