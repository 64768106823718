import React, { Component } from 'react'
import {
  App,
  Layout,
  Space,
  Typography,
  Button,
  ColorPicker,
  Row,
  Col,
  Card,
  Popconfirm
} from 'antd'
import { UndoOutlined } from '@ant-design/icons';

import I18n from '../../../I18n/I18n'
import {
  getTeamColors,
  updateTeamColors
} from '../../../Services/ServerAccessService'
import AppColorPreview, { defaultColours } from '../../../Images/SVG/AppColorPreview'

import Log from '../../../Utils/Log'
const log = new Log('Components/Dashboard/Content/Colors')

const { Content } = Layout
const { Title, Text } = Typography

// Styles
const invisibleSpace = {
  padding: 5
}

const saveButtonStyle = {
  paddingTop: 25,
  justifyContent: 'center',
  width: '100%'
}

const ResetColorButton = ({
  onConfirm = () => null
}) => {
  return (
    <Popconfirm
      title={I18n.t('Colors.resetAlertTitle')}
      description={I18n.t('Colors.resetAlertMessage')}
      okText={I18n.t('Common.yes')}
      cancelText={I18n.t('Common.no')}
      onConfirm={onConfirm}
    >
      <Button shape="circle" icon={<UndoOutlined />} />
    </Popconfirm>
  )
}


const ColorPickerComponent = ({
  onChangeComplete = () => {},
  label,
  defaultValue,
  value
}) => {
  return (
    <Space size={'large'}>
      <ColorPicker
        defaultValue={defaultValue}
        showText
        onChangeComplete={onChangeComplete}
        style={{ width: '150px' }}
        value={value}
      />
      <div style={invisibleSpace} />
      <Typography.Text style={{ alignSelf: 'center' }}>{label}</Typography.Text>
    </Space>
  )
}

function convertToHexValue (color) {
  return color.toHexString()
}

function withHooks (WrappedComponent) {
  return function (props) {
    const app = App.useApp()

    return <WrappedComponent app={app} {...props} />
  }
}

class Colors extends Component {
  constructor (props) {
    super(props)

    // Only default values must be defined (with null)
    this.state = {
      _lastUpdated: 0,
      primary: null,
      secondary: null,
      success: null,
      info: null,
      warning: null,
      danger: null,
      grey1: null,
      grey2: null,
      grey3: null,
      grey4: null,
      grey5: null,
      grey6: null,
      black: null,
      white: null,
      chatBubblesCoachBackground: null,
      chatBubblesCoachText: null,
      chatBubblesUserBackground: null,
      chatBubblesUserText: null
    }
  }

  async componentDidMount () {
    await this.getColors()
  }

  // Get data from server
  async getColors () {
    const result = await getTeamColors()

    log.debug('Get result:', result)

    this.setState({
      ...result
    })
  }

  // Update data on server
  async updateColors () {
    const {
      _lastUpdated,
      primary,
      secondary,
      success,
      info,
      warning,
      danger,
      grey1,
      grey2,
      grey3,
      grey4,
      grey5,
      grey6,
      black,
      white,
      chatBubblesCoachBackground,
      chatBubblesCoachText,
      chatBubblesUserBackground,
      chatBubblesUserText
    } = this.state

    // Update object
    const update = {
      _lastUpdated,
      primary,
      secondary,
      success,
      info,
      warning,
      danger,
      grey1,
      grey2,
      grey3,
      grey4,
      grey5,
      grey6,
      black,
      white,
      chatBubblesCoachBackground,
      chatBubblesCoachText,
      chatBubblesUserBackground,
      chatBubblesUserText
    }
    log.debug('Update object:', update)

    const result = await updateTeamColors(update)

    log.debug('Update result:', result)

    if (result === null) {
      // Unknown error
      this.props.app.message.error(I18n.t('Common.remoteSyncError'))
    } else if (result === undefined) {
      // Inconsistent
      this.props.app.message.warning(I18n.t('Common.remoteSyncInconsistent'))

      await this.getColors()
    } else {
      // Success
      this.props.app.message.success(I18n.t('Common.remoteSyncSaved'))

      this.setState({ ...result })
    }
  }

  renderColorPicker (name, value) {
    const upperCaseName = name.charAt(0).toUpperCase() + name.slice(1)

    return (
      <Row align={'stretch'}>
        <Space size={'small'}>
          <ColorPickerComponent
            defaultValue={value}
            onChangeComplete={(color) => {
              this.setState({ [name]: convertToHexValue(color) })
            }}
            value={this.state[name]}
            label={I18n.t('Colors.choose' + upperCaseName + 'ColorLabel')}
          />
        </Space>
      </Row>
    )
  }

  render () {
    const {
      _lastUpdated,
      primary,
      secondary,
      success,
      info,
      warning,
      danger,
      grey1,
      grey2,
      grey3,
      grey4,
      grey5,
      grey6,
      black,
      white,
      chatBubblesCoachBackground,
      chatBubblesCoachText,
      chatBubblesUserBackground,
      chatBubblesUserText
    } = this.state

    if (_lastUpdated > 0) {
      return (
        <Content>
          <Title level={2}>{I18n.t('Colors.title')}</Title>
          <div style={invisibleSpace} />
          <Row gutter={8}>
            <Col md={24} lg={16}>
              <Row gutter={8}>
                <Col span={24}>
                  <Card title={I18n.t('Colors.primaryCardTitle')} extra={
                      <ResetColorButton onConfirm={
                        () => this.setState({
                          primary: defaultColours.primary,
                          secondary: defaultColours.secondary
                        })}
                      />}
                    >
                    <Row justify={'space-around'}>
                      <Col>{this.renderColorPicker('primary', primary)}</Col>
                      <Col>
                        {this.renderColorPicker('secondary', secondary)}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <div style={invisibleSpace} />
              <Row gutter={8}>
                <Col md={24} lg={12}>
                  <Card title={I18n.t('Colors.greyCardTitle')} extra={
                    <ResetColorButton onConfirm={
                      () => this.setState({
                        grey1: defaultColours.grey1,
                        grey2: defaultColours.grey2,
                        grey3: defaultColours.grey3,
                        grey4: defaultColours.grey4,
                        grey5: defaultColours.grey5,
                        grey6: defaultColours.grey6
                      })}
                    />}
                  >
                    {this.renderColorPicker('grey1', grey1)}
                    {this.renderColorPicker('grey2', grey2)}
                    {this.renderColorPicker('grey3', grey3)}
                    {this.renderColorPicker('grey4', grey4)}
                    {this.renderColorPicker('grey5', grey5)}
                    {this.renderColorPicker('grey6', grey6)}
                  </Card>
                </Col>
                <Col md={24} lg={12}>
                  <Card
                    title={I18n.t('Colors.statusCardTitle')}
                    style={{ height: '100%' }}
                    extra={
                      <ResetColorButton onConfirm={
                        () => this.setState({
                          success: defaultColours.success,
                          info: defaultColours.info,
                          warning: defaultColours.warning,
                          danger: defaultColours.danger,
                        })}
                      />}
                    >
                    {this.renderColorPicker('success', success)}
                    {this.renderColorPicker('info', info)}
                    {this.renderColorPicker('warning', warning)}
                    {this.renderColorPicker('danger', danger)}
                  </Card>
                </Col>
              </Row>
              <div style={invisibleSpace} />
              <Row gutter={8}>
                <Col md={24} lg={12}>
                  <Card title={I18n.t('Colors.chatCardTitle')} 
                    extra={
                      <ResetColorButton onConfirm={
                        () => this.setState({
                          chatBubblesCoachBackground: defaultColours.chatBubblesCoachBackground,
                          chatBubblesCoachText: defaultColours.chatBubblesCoachText,
                          chatBubblesUserBackground: defaultColours.chatBubblesUserBackground,
                          chatBubblesUserText: defaultColours.chatBubblesUserText,
                        })}
                      />}
                    >
                    {this.renderColorPicker(
                      'chatBubblesCoachBackground',
                      chatBubblesCoachBackground
                    )}
                    {this.renderColorPicker(
                      'chatBubblesCoachText',
                      chatBubblesCoachText
                    )}
                    {this.renderColorPicker(
                      'chatBubblesUserBackground',
                      chatBubblesUserBackground
                    )}
                    {this.renderColorPicker(
                      'chatBubblesUserText',
                      chatBubblesUserText
                    )}
                  </Card>
                </Col>
                <Col md={24} lg={12}>
                  <Card
                    title={I18n.t('Colors.blackWhiteCardTitle')}
                    style={{ height: '100%' }}
                    extra={
                      <ResetColorButton onConfirm={
                        () => this.setState({
                          black: defaultColours.black,
                          white: defaultColours.white
                        })}
                      />}
                    >
                    {this.renderColorPicker('black', black)}
                    {this.renderColorPicker('white', white)}
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md={24} lg={8}>
              <Card title={I18n.t('Colors.appPreviewCardTitle')}>
                <AppColorPreview colors={this.state} />
              </Card>
            </Col>
          </Row>
          <Space style={saveButtonStyle}>
            <Button
              type='primary'
              onClick={() => {
                this.updateColors()
              }}
            >
              {I18n.t('Common.save')}
            </Button>
          </Space>
        </Content>
      )
    } else {
      return null
    }
  }
}

export default withHooks(Colors)
