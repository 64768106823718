import React, { Component } from 'react'
import { Provider } from 'react-redux'

import { App, ConfigProvider } from 'antd'
import enGB from 'antd/locale/en_GB'

import createReduxStore from './Redux'
import AppNavigation from './Navigation/AppNavigation'
import { initialize as initializeServerAccessService } from './Services/ServerAccessService'

import Log from './Utils/Log'
const log = new Log('App')

// Create redux store
const { store } = createReduxStore()

// Initialiue server access service
initializeServerAccessService(store)
class MyApp extends Component {
  constructor (props) {
    super(props)

    log.debug('Startup...')

    log.info('App startup complete.')
  }

  render () {
    return (
      <Provider store={store}>
        <ConfigProvider locale={enGB}>
          <App>
            <AppNavigation />
          </App>
        </ConfigProvider>
      </Provider>
    )
  }
}

export default MyApp
