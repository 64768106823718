export function objectIdString (objectId) {
  return objectId.clusterId + '-' + objectId.objectId
}

export function objectIdEquals (objectId1, objectId2) {
  return (
    objectId1?.clusterId === objectId2?.clusterId &&
    objectId1?.objectId === objectId2?.objectId
  )
}
