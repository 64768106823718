import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import { LockOutlined, UserOutlined, SafetyOutlined } from '@ant-design/icons'
import { Input, Button, message, Modal, Form } from 'antd'
import { connect } from 'react-redux'

import I18n from '../../I18n/I18n'
import AppConfig from '../../Config/AppConfig'
import { login } from '../../Services/ServerAccessService'

import Log from '../../Utils/Log'
const log = new Log('Components/Login/LoginForm')

const FormItem = Form.Item

class LoginForm extends Component {
  state = {
    showLoader: false
  }

  handleSubmit = async (values, history) => {
    this.setState({ showLoader: true })

    log.debug('Received values of form: ', values)

    // Try to perform login
    const response = await login(
      values.username,
      values.password,
      values.authCode
    )

    if (response === 'ok') {
      // Login successful...
      history.push(AppConfig.project.path + 'dashboard')
    } else if (response === 'legal-documents') {
      // Login successful, but show legal documents first...
      history.push(AppConfig.project.path + 'legal-documents')
    } else if (response === 'blocked') {
      message.warning(I18n.t('Login.loginBlocked'))

      this.setState({ showLoader: false })
    } else {
      message.warning(I18n.t('Login.loginFailed'))

      this.setState({ showLoader: false })
    }
  }

  showForgotPasswordInfo () {
    Modal.info({
      title: I18n.t('Login.forgotPassword'),
      content: I18n.t('Login.forgotPasswordInstruction'),
      onOk () {}
    })
  }

  render () {
    return (
      <Route
        render={({ history }) => (
          <Form
            name='login'
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                if (this.form) {
                  this.form.submit()
                }
              }
            }}
            ref={(node) => {
              this.form = node
            }}
            onFinish={(values) => this.handleSubmit(values, history)}
            className='login-form'
          >
            <FormItem
              name='username'
              rules={[
                {
                  required: true,
                  message: I18n.t('Login.validationError.username')
                }
              ]}
            >
              <Input
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder={I18n.t('Login.username')}
                autoComplete='username'
              />
            </FormItem>
            <FormItem
              name='password'
              rules={[
                {
                  required: true,
                  message: I18n.t('Login.validationError.password')
                }
              ]}
            >
              <Input
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                type='password'
                placeholder={I18n.t('Login.password')}
                autoComplete='current-password'
              />
            </FormItem>
            <FormItem
              name='authCode'
              rules={[
                {
                  required: true,
                  message: I18n.t('Login.validationError.authCode')
                }
              ]}
            >
              <Input
                prefix={<SafetyOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder={I18n.t('Login.authCode')}
              />
            </FormItem>
            <FormItem style={{ marginBottom: 0 }}>
              <button
                className='login-form-forgot'
                onClick={(e) => {
                  e.preventDefault()
                  this.showForgotPasswordInfo()
                }}
              >
                {I18n.t('Login.forgotPassword')}
              </button>
              <Button
                loading={this.state.showLoader}
                type='primary'
                htmlType='submit'
                className='login-form-button'
              >
                {I18n.t('Login.loginButton')}
              </Button>
            </FormItem>
          </Form>
        )}
      />
    )
  }
}

const mapStateToDispatch = (dispatch) => ({})

export default connect(null, mapStateToDispatch)(LoginForm)
