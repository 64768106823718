import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import { Button, message, Form } from 'antd'
import { connect } from 'react-redux'

import I18n from '../../I18n/I18n'
import AppConfig from '../../Config/AppConfig'
import { acceptLegalDocuments } from '../../Services/ServerAccessService'

// import Log from '../../Utils/Log'
// const log = new Log('Components/LegalDocuments/LegalDocumentsForm')

const FormItem = Form.Item

class LegalDocumentsForm extends Component {
  state = {
    showLoader: false
  }

  handleSubmit = async (values, history) => {
    this.setState({ showLoader: true })

    const { legalDocumentsToAccept } = this.props

    // Try to accept legal documents
    const response = await acceptLegalDocuments(legalDocumentsToAccept)

    if (response === 'ok') {
      history.push(AppConfig.project.path + 'dashboard')
    } else {
      message.warning(I18n.t('LegalDocuments.acceptFailed'))

      this.setState({ showLoader: false })

      history.push(AppConfig.project.path + 'login')
    }
  }

  renderLegalDocumentsToAccept () {
    const { legalDocumentsToAccept } = this.props

    const documents = []

    let i = 0
    for (const document of legalDocumentsToAccept) {
      documents.push(
        <li key={'document-' + i++}>
          <a href={document.url} target='_blank' rel='noreferrer'>
            {document.name}
          </a>
        </li>
      )
    }

    return documents
  }

  render () {
    return (
      <Route
        render={({ history }) => (
          <Form
            name='legal-documents'
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                if (this.form) {
                  this.form.submit()
                }
              }
            }}
            ref={(node) => {
              this.form = node
            }}
            onFinish={(values) => this.handleSubmit(values, history)}
            className='legal-documents-form'
          >
            <div>{I18n.t('LegalDocuments.infoText')}</div>
            <div className='legal-documents-list'>
              <ul>{this.renderLegalDocumentsToAccept()}</ul>
            </div>
            <FormItem style={{ marginBottom: 0 }}>
              <Button
                loading={this.state.showLoader}
                type='primary'
                htmlType='submit'
                className='legal-documents-form-button'
              >
                {I18n.t('LegalDocuments.acceptButton')}
              </Button>
            </FormItem>
          </Form>
        )}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  legalDocumentsToAccept: state.guiState.legalDocumentsToAccept
})

const mapStateToDispatch = (dispatch) => ({})

export default connect(mapStateToProps, mapStateToDispatch)(LegalDocumentsForm)
